<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Karyawan',
      posturl:'/staff',
      fields :[
        { key:'code',label:'Kode', type: 'input',rules:'required' },
        { key:'name',label:'Nama', type: 'input',rules:'required' },
        { key:'address',label:'Alamat', type: 'input',rules:'required' },
        { key:'city',label:'Kota', type: 'select-single',rules:'',dataurl:'/city'},
        { key:'postal_code',label:'Kode pos', type: 'input',rules:'' },
        { key:'phone',label:'No telephone', type: 'input',rules:'required' },
        { key:'email',label:'Email', type: 'input',rules:'required' },
        { key:'region',label:'Region', type: 'select-ajax',rules:'required',dataurl:'/region'},
        { key:'job_title',label:'Jabatan', type: 'select-ajax',rules:'required',dataurl:'/job-title' },
      ],
    }
  },
}
</script>